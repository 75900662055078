body {
  background-color: var(--background-primary);
  font: 14px "Century Gothic", Futura, sans-serif;
  color: var(--font-color);
}

h2 {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 2vh;
  font-size: 5vh;
  color: var(--font-color);
  transition-duration: .3s;
}

.noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.aboutMeText {
  font-size: 3vh;
  max-width: 60vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
}

#menuContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#menuDiv {
  margin: 0px 2vw 1.5vh 0px;

  #subMenu {
    transform: translateX(50vw);
    transition-duration: .3s;
  }

  .menuButton {
    font-size: 2vh;
    text-align: center;
    pointer-events: auto;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    padding: 1.5vh;
    margin-top: 1vh;
    transition-duration: .3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      background-color: var(--font-color);
      color: var(--background-primary);
    }
  }
}

.menuOpen {
  #subMenu {
    transform: translateX(0px) !important;
  }

  > .menuButton {
    transform: rotateZ(-90deg);
  }
}

#header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  img {
    aspect-ratio: 1/1;
    width: 20vw;
    border-radius: 50%;
  }

  h1 {
    text-align: center;
    margin-top: 5vh;
    font-size: 8vh;
    color: var(--font-color);
    transition-duration: .3s;
  }

  div {
    display: flex;
    justify-content: center;
    height: fit-content;
    flex-flow: row wrap;
    margin-top: 10vh;
    max-width: 60vw;
  }
}

.socialMedia {
  font-size: 3vh;
  margin: 0.5vh 1.5vw 0px 1.5vw;
  color: var(--font-color);
  text-decoration: none;
  height: fit-content;

  svg {
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    padding: 2vh;
    transition-duration: .3s;

    &:hover {
      transform: scale(1.3);
    }
  }
}

#SocialsTwitter {
  svg:hover {
    background-color: #00acee;
    border-color: #00acee;
  }
}

#SocialsGithub {
  svg:hover {
    background-color: var(--font-color);
    color: var(--background-primary);
  }
}

#SocialsGitlab {
  svg:hover {
    background-color: #fc6d26;
    border-color: #fc6d26;
  }
}

#SocialsLinkedin {
  svg:hover {
    background-color: #0e76a8;
    border-color: #0e76a8;
  }
}

#SocialsPatreon {
  svg:hover {
    background-color: #f96854;
    border-color: #f96854;
  }
}

#SocialsDiscord {
  svg:hover {
    background-color: #5865F2;
    border-color: #5865F2;
  }
}

#ScrollerButton {
  font-size: 3vh;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  color: var(--font-color);
  text-decoration: none;
  transition-duration: .3s;

  &:hover {
    transform: scale(1.3);
    svg {
      background-color: var(--font-color);
      color: var(--background-primary);
    }
  }

  svg {
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    padding: 2vh;
    transition-duration: .3s;
  }
}

hr {
  width: 70vw;
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  border-top: solid 2px var(--font-color);
  transition-duration: .3s;
}

#skillsDiv {
  max-width: 60vw;
  min-height: 7vh;
  margin-bottom: 5vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;

  p {
    font-size: 5vh;
    margin-left: 1vw;
    margin-right: 1vw;
    cursor: pointer;
    transition-duration: .3s;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      transform: scale(1.3);
    }
  }

  #languageCs {
    font-weight: 600;
  }

  #languageHtml:hover {
    color: #F66A3A;
  }

  #languageCss:hover {
    color: #18A1DC;
  }

  #languageJs:hover {
    color: #FFDE24;
  }

  #languagePhp:hover {
    color: #8F9ED1;
  }

  #languageLaravel:hover {
    color: #EF3B2D;
  }

  #languageReact:hover {
    color: #61DBFB;
  }

  #languageNode:hover {
    color: #78B742;
  }

  #languageBlender:hover {
    color: #EA882A;
  }

  #languageBootstrap:hover {
    color: #7311F6;
  }
}

#projectsDiv {
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;

  .projectCard {
    min-width: 300px;
    max-width: 15vw;
    margin: 0px 1vw 2vh 1vw;
    border: 2px solid var(--background-tertiary);
    border-radius: 10px;
    background-color: var(--background-secondary);
    overflow: hidden;
    transition-duration: .3s;

    img {
      width: 100%;
      transition-duration: .3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

    h3 {
      margin-top: 1vh;
      font-size: 2vh;
      text-align: center;
    }

    p {
      margin: 2vh 1vw 2vh 1vw;
      transition-duration: .3s;
    }

    .cardButton {
      display: block;
      width: 70%;
      margin: 1.3vh auto 1.3vh auto;
      padding: 1vh 1vw 1vh 1vw;
      background-color: #2D8F00;
      color: white;
      text-decoration: none;
      border-radius: 5px;
      transition-duration: .3s;

      &:hover {
        background-color: #3AB800;
        transform: scale(1.1);
      }

      svg {
        margin-right: 1vw;
      }
    }

    .cardButtonBetterDiscord {
      background-color: #5865F2;

      &:hover {
        background-color: #717CF2;
      }
    }

    .cardButtonGithub {
      background-color: #000;

      &:hover {
        background-color: #0f0f0f;
      }
    }

    .cardButtonGitlab {
      background-color: #fc6d26;

      &:hover {
        background-color: #fca326;
      }
    }

    .cardButtonDCS {
      background-color: #FCB941;

      &:hover {
        background-color: #FAC971;
      }
    }
  }
}

#legalContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(.5rem);
  background: rgba(0, 0, 0, 0.2);

  transition-duration: .3s;

  #legalDiv {
    width: 70vw;
    height: 90vh;
    padding-bottom: 6vh;
    border-radius: 20px;

    background-color: var(--background-primary);

    pointer-events: all;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .closeButton {
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2vh;
      font-weight: bold;
      pointer-events: auto;
      width: 2vw;
      border-radius: 50%;
      padding: 0.5rem;
      transition-duration: .3s;
      cursor: pointer;
      float: right;
      margin: 2vh 1vw 0 0;

      &:hover {
        transform: scale(1.1);
        background-color: var(--font-color);
        color: var(--background-primary);
      }
    }

    h6 {
      margin: 3vh auto 0px auto;
      width: fit-content;
      text-align: start;
      font-size: 4vh;
    }

    h2, h3, h4 {
      margin: 3vh auto 0px auto;
      width: fit-content;
      text-align: center;
      font-size: 3.5vh;
    }

    li {
      font-size: 3vh;
      margin: 3vh auto 0px auto;
      width: 90%;
    }

    p {
      margin: 3vh auto 0px auto;
      width: fit-content;
      max-width: 80%;
      white-space: pre-wrap;
      font-size: 3vh;
      text-align: start;
    }
  }
}

.legalHidden {
  display: none !important;
}
